import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import AllVideos from '../videos/all';

const IndexPage = ({ pageContext, location }) => {
	const [videoCount, setVideoCount] = useState(6);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-row-3p5"></div>
			<AllVideos
				videoCount={videoCount}
				handleShowMore={handleShowMore}
				pageContext={pageContext}
				location={location}
				includeLayout={false}
				includeNav={true}
				includeBreadcrumb={true}
			/>
		</Layout>
	);
};

export default IndexPage;
